<template>
  <div class="fengtan my-page-wrap">
    <div class="inner">
      <div class="content common-content">
        <div class="left content-left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div class="center main-content">
          <!-- <div class="tabs2">
            <div class="tab" :class="{ active: item.category_id == activeId }" v-for="item in tabs" :key="item.label" @click="tabClick(item)">
              <img :src="item.img" />
              <span>{{ item.label }}</span>
            </div>
          </div> -->

          <div class="list">
            <div class="card" v-for="item in list" :key="item.id">
              <div class="thumb">
                <img :src="$baseRemote + item.sku_image" class="image" />
              </div>
              <div class="right">
                <!-- <div class="title_1"><h2> {{ item.sku_name }} {{ item.label_name }}</h2></div> -->
                <h2>{{ item.sku_name }} {{ item.label_name }}</h2>

                <div class="subscribe">
                  <p>{{ item.introduction }}</p>
                </div>

                <div class="btn">
                  <el-button type="danger" size="mini" @click="goSku(item.sku_id)" >{{getbtntxt(item.goods_id)}}</el-button>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="pagination">
            <el-pagination background layout="prev, pager, next" :total="count"> </el-pagination>
          </div> -->
        </div>
        <div class="right content-right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from "@/utils/http"

  const img1 = require("@/assets/imgs/fengtan_1.png")
  const img2 = require("@/assets/imgs/fengtan_2.png")
  const img3 = require("@/assets/imgs/fengtan_3.png")

  export default {
    data() {
      return {
        activeId: "",
        list: [],
        count: 0,
        tabs: [
          // {
          //   label: "封坛原浆酒(封坛)",
          //   category_id: "10",
          //   img: img1,
          //   width: "80px",
          //   btntext: "立即封坛"
          // },
          // {
          //   label: "企业定制酒(瓶装)",
          //   category_id: "11",
          //   img: img2,
          //   width: "80px",
          //   btntext: "立即定制"
          // },
          // {
          //   label: "取样酒品(样酒)",
          //   category_id: "12",
          //   img: img3,
          //   width: "80px",
          //   btntext: "立即取样"
          // }
        ]
      }
    },

    mounted() {
      this.tabClick({ category_id: 3 })
    },
    methods: {
      tabClick(item) {
        // this.activeId = item.category_id
        this.getlist(item.category_id)
      },
      getlist(category_id) {
        http({
          url: "/api/goodssku/page",
          data: { page: 1, page_size: 12, category_id: category_id }
        }).then((res) => {
          this.list = res.data.list
          this.count = res.data.count
        })
      },
      goSku(skuId) {
        this.$router.pushToTab("/sku-" + skuId)
      },
      getbtntxt(goods_id){
        if(goods_id===14){
          return "立即封坛";
        }else if(goods_id===15){
          return "立即定制";
        }else if(goods_id===16){
          return "立即取样";
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .fengtan {
    background-color: #f9f9f9;
    .tabs2 {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      .tab {
        background-color: #fff;
        cursor: pointer;
        box-sizing: border-box;
        padding: 0 30px 20px;
        margin-right: 15px;
        border-radius: 20px;
        color: #070707;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 0.12rem;
        // border: 1px solid #efefef;
        img {
          width: 160px;
          height: 160px;
        }

        &.active {
          // border: 1px solid #a20000;
          color: #a20000;
        }
      }
      .tab:hover {
        // border: 1px solid #a20000;
        color: #a20000;
      }
    }

    .el-button:hover {
      width: 121px;
    }

    .pagination {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .list{
      display: flex;
      flex-direction: column-reverse;
    }
    .card {
      background-color: #fff;
      display: flex;
      padding: 20px 10px;
      margin-bottom: 25px;
      // border-bottom: 1px solid #e4e4e4;
      .thumb {
        width: 200px;
        height: 200px;
        margin-right: 70px;
        .image {
          max-width: 100%;
          transition: 0.6s 0.05s;
        }
      }
      .right {
        flex: 1;
        text-align: left;
        .title_1 {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
        }
        h2 {
          font-size: 20px;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .subscribe {
          margin-top: 18px;
          min-height: 6em;
          font-size: 16px;
          // font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .btn {
          margin-top:6px;
          .el-button {
            width: 108px;
            height:40px;
            background: #a20000;
            border-radius:6px;
            border: none;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .date-num {
      }
    }
    .card:hover {
      // border-bottom: 1px solid #a20000;
      .image {
        transform: scale(1.1);
      }
      h2 {
        color: #a20000;
      }
      .subscribe {
        color: #a20000;
      }
    }
  }
</style>
<style lang="scss">
  .page {
    .pagination {
      .active {
        background: #a20000;
        color: white;
      }
    }
  }
</style>
